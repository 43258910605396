.close {
  box-shadow: 0;
  height: 0px;
  overflow-y: hidden;
  transition: height ease 0.4s, box-shadow ease 0.4s, border-bottom ease 0.4s,
    padding ease 0.4s;
  border-bottom: solid #4c6cd3 0px;
}

.open {
  box-shadow: inset 0px 0.3px 1.5px 0px #000;
  height: 100px;
  transition: height ease 0.4s;
  overflow-y: scroll;
}

.open-mobile {
  box-shadow: inset 0px 0.3px 1.5px 0px #000;
  height: 240px;
  transition: height ease 0.4s, box-shadow ease 0.4s, border-bottom ease 0.4s,
    padding ease 0.4s;
  border-bottom: solid #4c6cd3 2px;
  padding: 2px 2px 2px 0;
}

.flip-vertical {
  transform: rotate(-180deg);
  transition: transform ease 0.4s;
}

.blur-in {
  transition: filter 0.25s ease-out;
  filter: blur(0px);
}

/* Storefront Cart */
.close-store-cart {
  height: 0px;
  transition: height 0.2s ease-out;
}

.open-store-cart {
  height: 240px;
  transition: height 0.2s ease-out;
  border-bottom: solid #444444 2px;
}

.remove {
  width: 0px;
  overflow: hidden;
}

.reveal-remove {
  width: 100%;
  background-color: #b73c3c;
  transition: width 0.2s ease-out;
}

.remove-text {
  opacity: 0;
}

.reveal-remove-text {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.reveal-cart-total-tax {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
