@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .em-checkbox-wrapper input[type='checkbox'] {
    --active: #4c6cd3;
    --active-inner: #f2f2f2;
    --focus: 2px #7d87adcc;
    --border: #bbc1e1;
    --border-hover: #4c6cd3;
    --background: #f2f2f2;
    --disabled: #b0b0b0;
    --disabled-inner: #444444;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  .em-checkbox-wrapper input[type='checkbox']:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }
  .em-checkbox-wrapper input[type='checkbox']:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  .em-checkbox-wrapper input[type='checkbox']:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  .em-checkbox-wrapper input[type='checkbox']:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  .em-checkbox-wrapper input[type='checkbox']:disabled + label {
    cursor: not-allowed;
  }
  .em-checkbox-wrapper
    input[type='checkbox']:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  .em-checkbox-wrapper input[type='checkbox']:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  .em-checkbox-wrapper input[type='checkbox']:not(.switch) {
    width: 21px;
  }
  .em-checkbox-wrapper input[type='checkbox']:not(.switch):after {
    opacity: var(--o, 0);
  }
  .em-checkbox-wrapper input[type='checkbox']:not(.switch):checked {
    --o: 1;
  }
  .em-checkbox-wrapper input[type='checkbox'] + label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 4px;
  }

  .em-checkbox-wrapper input[type='checkbox']:not(.switch) {
    border-radius: 7px;
  }
  .em-checkbox-wrapper input[type='checkbox']:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }
  .em-checkbox-wrapper input[type='checkbox']:not(.switch):checked {
    --r: 43deg;
  }
}

.em-checkbox-wrapper * {
  box-sizing: inherit;
}
.em-checkbox-wrapper *:before,
.em-checkbox-wrapper *:after {
  box-sizing: inherit;
}
