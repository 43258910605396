input:focus,
input:active,
input:focus-visible {
  outline: none;
}

textarea:focus,
textarea:active,
textarea:focus-visible {
  outline: none;
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

.homeSwiper .swiper-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

#tsparticles canvas:not(.visible) {
  opacity: 0 !important;
  transition: none;
}

#tsparticles canvas.visible {
  opacity: 1 !important;
  transition: opacity 2s ease-in;
}
